import { inject }                            from 'aurelia-framework';
import { MaterialTypesRepository }           from 'modules/management/dynamic-fields/material-types/services/repository';
import { SpecificationRevisionMaterialType } from 'modules/management/models/specification-revision-material-type';
import { SectorsRepository }                 from 'modules/management/specifications/sectors/services/repository';

@inject(MaterialTypesRepository, SectorsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param materialTypesRepository
     * @param sectorsRepository
     */
    constructor(materialTypesRepository, sectorsRepository) {
        this.materialTypesRepository = materialTypesRepository;
        this.sectorsRepository       = sectorsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {SpecificationRevisionMaterialType}
     */
    model(code, order) {
        let model = new SpecificationRevisionMaterialType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @param create
     * @returns {*[]}
     */
    schema(viewModel, readonly = false, create = false) {

        this.sector = {
            type:       'text',
            key:        'sector',
            label:      'form.field.sector',
            size:       12,
            attributes: {
                readonly: true,
            },
        };

        this.material_types = {
            type:         'duallistbox',
            key:          'material_types',
            label:        'form.field.material-types',
            size:         12,
            remoteSource: () => this.materialTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.sectors = {
            type:         'duallistbox',
            key:          'sectors',
            label:        'form.field.sectors',
            required:     false,
            size:         12,
            remoteSource: () => this.sectorsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        const schema = create ? [
            [this.material_types],
            [this.sectors],
        ] : [
            [this.sector],
            [this.material_types],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
